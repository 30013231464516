<template>
  <div class="api">
    <div class="container pt-lg-2 pl-0">
      <div aria-label="breadcrumb">
        <ol class="breadcrumb pb-2">
          <li class="breadcrumb-item"><router-link :to="{ path: `/${$route.params.lang}/`}">{{ $t('Header.Home') }}</router-link></li>
          <li class="breadcrumb-item active" aria-current="page"><a class="disabled">{{ $t('Header.Pricing') }}</a></li>
        </ol>
      </div>
    </div>
    <div id="scroll-to-div" class="main-container">
      <div id="stickyBlockStartPoint" class="col-lg-2 pr-xl-5 mb-5 mb-lg-0" />
      <div class="container space-lg-1">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto">
          <h1 class="h2 pricing-title">{{ $t('PricingPage.Title') }}</h1>
          <p class="pricing-description">{{ $t('PricingPage.Description') }}</p>
        </div>
      </div>

      <div id="developer-plan" class="container space-top-1 space-bottom-2">
        <div class="row align-items-lg-center">
          <div class="col-lg-6 mb-5 mb-lg-0 order-lg-1">
            <div class="w-80 w-lg-100 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/be-user.svg">
            </div>
          </div>
          <div class="col-lg-6 order-lg-0">
            <h3 class="mb-3">{{ $t('PricingPage.OAHDeveloperPlan.Title') }}</h3>
            <p>{{ $t('PricingPage.OAHDeveloperPlan.Description') }}</p>
            <div class="media mb-3">
              <span class="icon icon-xs icon-soft-primary icon-circle mr-3"><i class="fas fa-check" /></span>
              <div class="media-body">{{ $t('PricingPage.OAHDeveloperPlan.Feature1') }}</div>
            </div>
            <div class="media mb-3">
              <span class="icon icon-xs icon-soft-primary icon-circle mr-3"><i class="fas fa-check" /></span>
              <div class="media-body">{{ $t('PricingPage.OAHDeveloperPlan.Feature2') }}</div>
            </div>
            <div class="media mb-3">
              <span class="icon icon-xs icon-soft-primary icon-circle mr-3"><i class="fas fa-check" /></span>
              <div class="media-body">{{ $t('PricingPage.OAHDeveloperPlan.Feature3') }}</div>
            </div>
            <a id="ga-M01g-07" href="https://hub.openapihub.com/login" target="_blank" class="btn btn-primary register-button transition-3d-hover text-white mt-3">{{ $t('PricingPage.OAHDeveloperPlan.RegisterButton') }}</a>
            <hr class="my-5">
            <h5>{{ $t('PricingPage.OAHDeveloperPlan.ProviderTips') }}</h5>
            <div class="scroll-link" @click="scrollToProviderPlan">{{ $t('PricingPage.OAHDeveloperPlan.ProviderButton') }}<i class="fas fa-angle-down fa-sm ml-2" /></div>
          </div>
        </div>
      </div>

      <div id="provider-plan" class="bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/illustrations/ben-about-hero.svg`)})`}">
        <div class="container space-2 space-lg-3">
          <div class="plan-table-title-container w-lg-80 mx-auto">
            <h2 class="mb-3">{{ $t('PricingPage.OAHProviderPlan.Title') }}</h2>
            <p class="description">{{ $t('PricingPage.OAHProviderPlan.Description') }}</p>
            <a href="https://www.fabrixapi.com/pricing" target="_blank" class="btn btn-primary register-button transition-3d-hover text-white mt-3">{{ $t('PricingPage.OAHProviderPlan.Button') }} <i class="fas fa-external-link-alt ml-2 small" /></a>
          </div>

          <!-- HIDDEN - OAH Portal Plans -->
          <div v-if="false">
            <!-- Annually/Monthly Toggle Switch -->
            <div class="text-center">
              <div class="btn-group btn-group-sm btn-group-toggle btn-group-segment mt-6 mb-7" style="background-color:rgba(113, 134, 157, 0.08)" data-toggle="buttons">
                <!-- <label
                class="btn"
                :class="{ active: period_option === 'annually' }"
                @click="selectPeriod('annually')"
              >
                <input
                  id="option1"
                  type="radio"
                  name="options"
                  checked
                > {{ $t('PricingPage.OAHProviderPlan.Annually') }}
                <span class="position-absolute top-0 right-0 text-nowrap mt-n6 mr-6 mr-sm-9">
                  <span class="badge badge-primary badge-pill py-sm-2 px-sm-3">{{ $t('PricingPage.OAHProviderPlan.AnnualSaver') }}</span>
                  <svg class="d-block position-absolute max-w-6rem mt-2 ml-n1 ml-sm-4 px-2 px-sm-0" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 12.14 8.76">
                    <path fill="none" stroke="#bdc5d1" stroke-width="0.5" stroke-linecap="round" stroke-miterlimit="10" d="M12.38,8C.72,10.75,1.06,1.17,1.06,1.17" transform="translate(-0.81 -0.92)" />
                    <polyline fill="none" stroke="#bdc5d1" stroke-width="0.5" stroke-linecap="round" stroke-miterlimit="10" points="10.18 5.76 11.88 7.05 10.42 8.51" />
                  </svg>
                </span>
              </label> -->

                <label
                  class="btn"
                  :class="{ active: period_option === 'monthly' }"
                  @click="selectPeriod('monthly')"
                >
                  <input
                    id="option2"
                    type="radio"
                    name="options"
                    checked
                  > {{ $t('PricingPage.OAHProviderPlan.Monthly') }}
                </label>
              </div>
            </div>
            <!-- End of Annually/Monthly Toggle Switch -->

            <!-- Mobile View Plan Buttons-->
            <div class="d-lg-none">
              <input v-for="option in planOptions" :id="option.value" :key="option.value" v-model="selectedPlanIdForTable" type="radio" name="categories" :value="option.value">
              <ol class="filters-btn">
                <li v-for="option in planOptions" :key="option.value" @click="changeOption(option.value)">
                  <label class="btn btn-xs btn-soft-secondary btn-pill py-2 px-3 m-1 category-filter" :class="{'selectedCategory': selectedPlanIdForTable === option.value}" :for="option.category">{{ option.label }}</label>
                </li>
              </ol>
            </div>
            <!-- End of Mobile View Plan Buttons-->

            <!-- Desktop View -->
            <table class="table d-none d-lg-block">
              <thead>
                <tr>
                  <th v-for="plan in plans" :key="plan.id" scope="col" :class="{'best-value': plan.is_best_value}">
                    <span v-if="plan.is_best_value">{{ $t('PricingPage.OAHProviderPlan.BestValue') }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="first-row">
                  <td v-for="(plan, index) in plans" :key="plan.id" class="plan-info-container" :class="{'best-value': plan.is_best_value, 'next-best-value' : (index < plans.length-2 && plans[index+1].is_best_value)}">
                    <div class="plan-name">{{ $t(plan.plan_name) }}</div>
                    <div class="plan-description">{{ $t(plan.plan_description) }}</div>
                  </td>
                </tr>
                <tr class="button">
                  <td v-for="(plan, index) in plans" :key="plan.id" class="plan-info-container" :class="{'best-value': plan.is_best_value, 'next-best-value' : (index < plans.length-2 && plans[index+1].is_best_value)}">
                    <div v-if="!plan.is_price_shown">
                      <div class="plan-price-container no-description">
                        <div class="plan-price">{{ $t('PricingPage.OAHProviderPlan.Custom') }}</div>
                      </div>
                    </div>
                    <div v-else-if="plan.plan_price_monthly === 0">
                      <div class="plan-price-container no-description">
                        <div class="plan-price">{{ $t('PricingPage.OAHProviderPlan.Free') }}</div>
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="period_option === 'annually'" class="d-block mb-1"><del>{{ plan.plan_currency }} {{ plan.plan_price_monthly }}</del></div>
                      <div class="plan-price-container">
                        <div class="plan-currency mr-1">{{ plan.plan_currency }}</div>
                        <div class="plan-price">{{ period_option === 'annually' ? plan.plan_price_annually : plan.plan_price_monthly }}</div>
                      </div>
                      <div class="price-description">{{ $t('PricingPage.OAHProviderPlan.SubscriptionCharge', { per_unit: $t(plan.plan_charge_type), billed_unit: $t(plan.plan_billing_period) }) }}</div>
                    <!-- <div class="price-description"><span v-if="period_option === 'annually'" class="badge badge-md badge-pill badge-soft-success">{{ plan.plan_saver }}</span></div> -->
                    </div>
                  </td>
                </tr>
                <tr class="button">
                  <td v-for="(plan, index) in plans" :key="plan.id" class="plan-info-container" :class="{'best-value': plan.is_best_value, 'next-best-value' : (index < plans.length-2 && plans[index+1].is_best_value)}">
                    <div class="plan-button">
                      <!-- <button v-if="plan.button_function === 'register'" class="btn btn-primary">Register Now</button> -->
                      <span v-if="plan.button_function === 'register'">
                        <a :id="findGaId(plan.id, 'table')" href="https://hub.openapihub.com/login" target="_blank" class="btn btn-primary btn-block">{{ $t('PricingPage.OAHProviderPlan.RegisterFree') }}</a>
                      </span>
                      <button v-else-if="plan.button_function === 'contact-us'" :id="findGaId(plan.id, 'table')" class="btn btn-primary" @click="contactUs">{{ $t('PricingPage.OAHProviderPlan.ContactUs') }}</button>
                      <span v-else-if="plan.button_function === 'register-upgrade'">
                        <a :id="findGaId(plan.id, 'table')" :href="$t('PricingPage.OAHProviderPlan.TopUpGuideURL')" target="_blank" class="btn btn-primary btn-block">{{ $t('PricingPage.OAHProviderPlan.RegisterNow') }}</a>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="last-row">
                  <td v-for="(plan, index) in plans" :key="plan.id" :class="{'best-value': plan.is_best_value, 'next-best-value' : (index < plans.length-2 && plans[index+1].is_best_value)}">
                    <hr class="hr">
                    <div v-if="index === 0" class="detail-header">{{ $t('PricingPage.OAHProviderPlan.Includes') }}</div>
                    <div v-else class="detail-header">{{ $t('PricingPage.OAHProviderPlan.FeaturesList', { tier: $t(plans[index - 1].plan_name) }) }}</div>
                    <div>
                      <div v-for="detail in plan.detail_list" :key="detail.title" class="detail-item">
                        <div class="title">{{ $t(detail.title) }}</div>
                        <div v-for="point in detail.points" :key="point" class="point">
                          <div><i class="fas fa-check mr-2 ml-2 icon" /></div>
                          <div>{{ $t(point) }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- End of Desktop View -->
            <!-- Mobile View -->
            <table class="table mobile-table">
              <thead>
                <tr>
                  <th scope="col" :class="{'best-value': selectedPlanForTable.is_best_value}">
                    <span v-if="selectedPlanForTable.is_best_value">{{ $t('PricingPage.OAHProviderPlan.BestValue') }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="first-row">
                  <td class="plan-info-container" :class="{'best-value': selectedPlanForTable.is_best_value}">
                    <div class="plan-name">{{ $t(selectedPlanForTable.plan_name) }}</div>
                    <div class="plan-description">{{ $t(selectedPlanForTable.plan_description) }}</div>
                  </td>
                </tr>
                <tr class="button">
                  <td class="plan-info-container" :class="{'best-value': selectedPlanForTable.is_best_value}">
                    <div v-if="!selectedPlanForTable.is_price_shown">
                      <div class="plan-price-container no-description">
                        <div class="plan-price">{{ $t('PricingPage.OAHProviderPlan.Custom') }}</div>
                      </div>
                    </div>
                    <div v-else-if="selectedPlanForTable.plan_price_monthly === 0">
                      <div class="plan-price-container no-description">
                        <div class="plan-price">{{ $t('PricingPage.OAHProviderPlan.Free') }}</div>
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="period_option === 'annually'" class="d-block mb-1"><del>{{ selectedPlanForTable.plan_currency }} {{ selectedPlanForTable.plan_price_monthly }}</del></div>
                      <div class="plan-price-container">
                        <div class="plan-currency mr-1">{{ selectedPlanForTable.plan_currency }}</div>
                        <div class="plan-price">{{ period_option === 'annually' ? selectedPlanForTable.plan_price_annually : selectedPlanForTable.plan_price_monthly }}</div>
                      </div>
                      <div class="price-description">{{ $t('PricingPage.OAHProviderPlan.SubscriptionCharge', { per_unit: $t(selectedPlanForTable.plan_charge_type), billed_unit: $t(selectedPlanForTable.plan_billing_period) }) }}</div>
                    <!-- <div class="price-description"><span v-if="period_option === 'annually'" class="badge badge-md badge-pill badge-soft-success">{{ selectedPlanForTable.plan_saver }}</span></div> -->
                    </div>
                  </td>
                </tr>
                <tr class="button">
                  <td class="plan-info-container" :class="{'best-value': selectedPlanForTable.is_best_value}">
                    <div class="plan-button">
                      <!-- <button v-if="plan.button_function === 'register'" class="btn btn-primary">Register Now</button> -->
                      <span v-if="selectedPlanForTable.button_function === 'register'">
                        <a :id="findGaId(selectedPlanForTable.id, 'table')" href="https://hub.openapihub.com/login" target="_blank" class="btn btn-primary btn-block">{{ $t('PricingPage.OAHProviderPlan.RegisterFree') }}</a>
                      </span>
                      <button v-else-if="selectedPlanForTable.button_function === 'contact-us'" :id="findGaId(selectedPlanForTable.id, 'table')" class="btn btn-primary" @click="contactUs">{{ $t('PricingPage.OAHProviderPlan.ContactUs') }}</button>
                      <span v-else-if="selectedPlanForTable.button_function === 'register-upgrade'">
                        <a :id="findGaId(selectedPlanForTable.id, 'table')" :href="$t('PricingPage.OAHProviderPlan.TopUpGuideURL')" target="_blank" class="btn btn-primary btn-block">{{ $t('PricingPage.OAHProviderPlan.RegisterNow') }}</a>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="last-row">
                  <td :class="{'best-value': selectedPlanForTable.is_best_value}">
                    <hr class="hr">
                    <div v-if="!getPeriousPlanName(selectedPlanIdForTable)" class="detail-header">{{ $t('PricingPage.OAHProviderPlan.Includes') }}</div>
                    <div v-else class="detail-header">{{ $t('PricingPage.OAHProviderPlan.FeaturesList', { tier: getPeriousPlanName(selectedPlanIdForTable) }) }}</div>
                    <div>
                      <div v-for="detail in selectedPlanForTable.detail_list" :key="detail.title" class="detail-item">
                        <div class="title">{{ $t(detail.title) }}</div>
                        <div v-for="point in detail.points" :key="point" class="point">
                          <div><i class="fas fa-check mr-2 ml-2 icon" /></div>
                          <div>{{ $t(point) }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- End of Mobile View -->
            <b-collapse :id="'collapsePlanTable'">
              <div class="feature-list-container">
                <div class="space-bottom-1">
                  <hr>
                  <h3 class="text-center py-4">{{ $t('PricingPage.OAHProviderPlan.CompareTitle') }}</h3>
                  <div class="card">
                    <!-- Select dropdown only appear in Tablet and Mobile -->
                    <div class="px-3 pt-4 d-lg-none">
                      <select id="selectForFeatureList" class="form-control custom-select" name="plan">
                        <option v-for="option in planOptions" :key="option.value" :value="option.value" :selected="option.value === selectedPlanIdForFeature">{{ option.label }}</option>
                      </select>
                    </div>

                    <!-- Full Feature Table -->
                    <!-- Desktop View -->
                    <div class="table-responsive-lg d-none d-lg-block">
                      <table class="feature-table table-borderless mb-0">
                        <thead class="text-center">
                          <tr class="border-bottom">
                            <th scope="col" class="w-50 py-4 align-items">
                              <span class="text-dark float-left pl-1">{{ $t('PricingPage.OAHProviderPlan.Features') }}</span>
                            </th>
                            <th v-for="plan in plans" :key="plan.id" scope="col" class="w-10 py-4 plan-name">
                              <span class="text-dark h4">{{ $t(plan.plan_name) }}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-for="(group, groupIndex) in plans[0].feature_list" :key="group.group_name" class="font-size-1">
                          <tr>
                            <th scope="row" colspan="6" class="bg-white text-dark pt-4 pb-2 pl-3">{{ $t(group.group_name) }}</th>
                          </tr>
                          <tr v-for="(feature, featureIndex) in group.features" :key="feature.feature_name">
                            <th scope="row" class="py-1 pl-3 font-weight-normal text-secondary">{{ $t(feature.feature_name) }}</th>
                            <td v-for="plan in plans" :key="plan.id" class="text-center py-1 align-middle">
                              <span v-if="plan.feature_list[groupIndex].features[featureIndex].detail">
                                {{ $t(plan.feature_list[groupIndex].features[featureIndex].detail) }}
                              </span>
                              <span v-else-if="plan.feature_list[groupIndex].features[featureIndex].isAllowed">
                                <i class="fas fa-check text-primary" />
                              </span>
                              <span v-else>
                                <i class="fas fa-times text-muted" />
                              </span>
                            </td>
                          </tr>
                        </tbody>
                        <tbody class="font-size-1">
                          <tr>
                            <th scope="row" class="bg-white py-4" />
                            <td v-for="plan in plans" :key="plan.id" class="bg-white text-center py-4">
                              <span v-if="plan.button_function === 'register'">
                                <a :id="findGaId(plan.id, 'list')" href="https://hub.openapihub.com/login" target="_blank" class="btn btn-sm btn-primary text-nowrap transition-3d-hover">{{ $t('PricingPage.OAHProviderPlan.RegisterFree') }}</a>
                              </span>
                              <button v-else-if="plan.button_function === 'contact-us'" :id="findGaId(plan.id, 'list')" class="btn btn-sm btn-primary text-nowrap transition-3d-hover" @click="contactUs">{{ $t('PricingPage.OAHProviderPlan.ContactUs') }}</button>
                              <span v-else-if="plan.button_function === 'register-upgrade'">
                                <a :id="findGaId(plan.id, 'list')" :href="$t('PricingPage.OAHProviderPlan.TopUpGuideURL')" target="_blank" class="btn btn-sm btn-primary text-nowrap transition-3d-hover">{{ $t('PricingPage.OAHProviderPlan.RegisterNow') }}</a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- End of Desktop View -->
                    <!-- Mobile View -->
                    <div class="table-responsive-lg mobile-table">
                      <table v-if="selectedPlanForFeature" class="feature-table table-borderless mb-0">
                        <thead class="text-center">
                          <tr class="border-bottom">
                            <th scope="col" class="w-50 py-4 align-items">
                              <span class="text-dark float-left pl-1">{{ $t('PricingPage.OAHProviderPlan.Features') }}</span>
                            </th>
                            <th scope="col" class="w-50 py-4 plan-name">
                              <span class="text-dark h4">{{ $t(selectedPlanForFeature.plan_nam) }}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-for="(group, groupIndex) in selectedPlanForFeature.feature_list" :key="group.group_name" class="font-size-1">
                          <tr>
                            <th scope="row" colspan="6" class="bg-white text-dark pt-4 pb-2 pl-3">{{ $t(group.group_name) }}</th>
                          </tr>
                          <tr v-for="(feature, featureIndex) in group.features" :key="feature.feature_name">
                            <th scope="row" class="py-1 pl-3 font-weight-normal text-secondary">{{ $t(feature.feature_name) }}</th>
                            <td class="text-center py-1 align-middle">
                              <span v-if="selectedPlanForFeature.feature_list[groupIndex].features[featureIndex].detail">
                                {{ $t(selectedPlanForFeature.feature_list[groupIndex].features[featureIndex].detail) }}
                              </span>
                              <span v-else-if="selectedPlanForFeature.feature_list[groupIndex].features[featureIndex].isAllowed">
                                <i class="fas fa-check text-primary" />
                              </span>
                              <span v-else>
                                <i class="fas fa-times text-muted" />
                              </span>
                            </td>
                          </tr>
                        </tbody>
                        <tbody class="font-size-1">
                          <tr>
                            <th scope="row" class="bg-white py-4" />
                            <td class="bg-white text-center py-4">
                              <span v-if="selectedPlanForFeature.button_function === 'register'">
                                <a :id="findGaId(selectedPlanForFeature.id, 'list')" href="https://hub.openapihub.com/login" target="_blank" class="btn btn-sm btn-primary text-nowrap transition-3d-hover">{{ $t('PricingPage.OAHProviderPlan.RegisterFree') }}</a>
                              </span>
                              <button v-else-if="selectedPlanForFeature.button_function === 'contact-us'" :id="findGaId(selectedPlanForFeature.id, 'list')" class="btn btn-sm btn-primary text-nowrap transition-3d-hover" @click="contactUs">{{ $t('PricingPage.OAHProviderPlan.ContactUs') }}</button>
                              <span v-else-if="selectedPlanForFeature.button_function === 'register-upgrade'">
                                <a :id="findGaId(selectedPlanForFeature.id, 'list')" :href="$t('PricingPage.OAHProviderPlan.TopUpGuideURL')" target="_blank" class="btn btn-primary btn-block">{{ $t('PricingPage.OAHProviderPlan.RegisterNow') }}</a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  <!-- End of Mobile View -->
                  </div>
                </div>
              </div>
            </b-collapse>
            <div class="text-center">
              <div v-b-toggle="'collapsePlanTable'" class="link-collapse font-weight-bold" data-toggle="collapse" aria-expanded="false" aria-controls="collapsePlanTable">
                <span class="link-collapse-default">{{ $t('PricingPage.OAHProviderPlan.SeeAllFeatures') }}<i class="fas fa-chevron-down ml-2" /></span>
                <span class="link-collapse-active">{{ $t('PricingPage.OAHProviderPlan.SeeLessFeatures') }}<i class="fas fa-chevron-up ml-2" /></span>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- HIDDEN - Book a Demo -->
      <div v-if="false">
        <div class="container space-top-3 space-bottom-2">
          <div class="card border-0 shadow" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-hero-1.svg`)})`}">
            <div class="card-body text-center py-5">
              <h2>{{ $t('PricingPage.BookDemo.Title') }}</h2>
              <p>{{ $t('PricingPage.BookDemo.Description') }}</p>
              <router-link
                :to="{ path: `/${$route.params.lang}/contact`}"
                class="btn btn-primary mt-3"
              >{{ $t('PricingPage.BookDemo.Button') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- HIDDEN - FAQ -->
      <div v-if="false">
        <div id="faq" class="container">
          <div class="container space-2">
            <div class="text-center">
              <h3 class="faq-title">{{ $t('PricingPage.FAQ.Title') }}</h3>
            </div>
            <div id="faqList" role="tablist">
              <b-card v-for="(item, index) in FAQ" :key="index" no-body class="mb-3">
                <b-card-header header-tag="header" class="card-collapse" role="tab">
                  <h5 class="mb-0">
                    <button v-b-toggle="item.id" data-toggle="collapse" class="btn btn-link btn-block d-flex justify-content-between align-items-center card-btn p-3" :aria-controls="item.id">
                      {{ $t(item.question) }}
                      <span class="card-btn-toggle">
                        <span class="card-btn-toggle-default">+</span>
                        <span class="card-btn-toggle-active">−</span>
                      </span>
                    </button>
                  </h5>
                </b-card-header>
                <b-collapse :id="item.id" :accordion="'faqList'">
                  <b-card-body>
                    <b-card-text>
                      <div v-for="(text, textIndex) in item.answer" :key="textIndex">
                        <div :class="{'mb-2' : textIndex < item.answer.length}" v-html="$t(text)" />
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div id="ctaSection" class="container space-2 space-lg-3 cta-bg">
      <div class="text-center w-md-60 mx-auto">
        <h2 class="mb-3">{{ $t('PricingPage.BnCTA.Title') }}</h2>
        <p class="mb-4">{{ $t('PricingPage.BnCTA.Description') }}</p>
        <a id="ga-OW02-07-03" class="btn btn-primary transition-3d-hover" href="https://www.benovelty.com/contact" target="_blank">{{ $t('PricingPage.BnCTA.TalkToUsButton') }}</a>
      </div>
    </div>

  </div>
</template>

<script>
const $ = require('jquery')
import Plans from './plans.json'
import FAQ from './faq.json'

export default {
  name: 'Pricing',
  components: {
  },
  data() {
    return {
      plans: Plans.plans,
      FAQ: FAQ.FAQ,
      DEVELOPER_PORTAL_URL: '',
      isFeatureListShown: false,
      planOptions: [],
      selectedPlanIdForFeature: '',
      selectedPlanIdForTable: '',
      gaIdList: [
        { plan_id: 'plan-1', button_in_table_id: 'ga-M01g-12-05', button_in_list_id: 'ga-M01g-20' },
        { plan_id: 'plan-2', button_in_table_id: 'ga-M01g-13-05', button_in_list_id: 'ga-M01g-21' },
        { plan_id: 'plan-3', button_in_table_id: 'ga-M01g-14-05', button_in_list_id: 'ga-M01g-22' },
        { plan_id: 'plan-4', button_in_table_id: 'ga-M01g-15-05', button_in_list_id: 'ga-M01g-23' },
        { plan_id: 'plan-5', button_in_table_id: 'ga-M01g-16-05', button_in_list_id: 'ga-M01g-24' }
      ],
      period_option: 'monthly'
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters.login
    },
    selectedPlanForTable() {
      return this.plans.find(plan => plan.id === this.selectedPlanIdForTable)
    },
    selectedPlanForFeature() {
      return this.plans.find(plan => plan.id === this.selectedPlanIdForFeature)
    }
  },
  async created() {
    // document.title = 'Pricing | OpenAPIHub'
    this.planOptions = this.plans.map(plan => {
      return { value: plan.id, label: this.$t(plan.plan_name) }
    })
    this.selectedPlanIdForFeature = this.planOptions[0].value
    this.selectedPlanIdForTable = this.planOptions[0].value
  },
  mounted() {
    $('#selectForFeatureList').change(e => {
      this.selectedPlanIdForFeature = e.target.value
    })
  },
  methods: {
    clickLearnMoreButton() {
      if (this.loggedIn && this.userProfile.id !== '') {
        this.gotoTopUpProvider()
      } else {
        this.redirectToServiceDesk()
      }
    },
    redirectToServiceDesk() {
      window.open(`https://benovelty.atlassian.net/wiki/external/29851996/OGEyMjkxZDhjNzZjNDE3OGI2OTA4MDViZWQxNWM5MmU?atlOrigin=eyJpIjoiNjAwMzViNDFlNTdjNGU2Mjk4ODMxMDJjMGRhZTM1ODYiLCJwIjoiYyJ9`, '_blank')
    },
    gotoTopUpProvider() {
      window.open(`${this.DEVELOPER_PORTAL_URL}/#/be-a-provider`, '_blank')
    },
    getPeriousPlanName(id) {
      const index = this.plans.findIndex(plan => plan.id === id)
      return index === -1 || index === 0 ? null : this.$t(this.plans[index].plan_name)
    },
    scrollToProviderPlan() {
      var elmnt = document.getElementById('provider-plan')
      elmnt.scrollIntoView({ behavior: 'smooth' })
    },
    contactUs() {
      const script = document.createElement('script')
      script.src = 'https://js.hsforms.net/forms/v2.js'
      document.body.appendChild(script)
      script.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '7091000',
            formId: 'b20fb3bd-0aea-45b5-9493-c02301f45050',
            errorClass: 'hubspotFormError',
            target: `#hubspotPlanForm`
          })
        }
      })
      this.$nextTick(() => {
        this.$refs['hubspot-form-dialog'].show()
      })
    },
    registerUpgrade() {
      this.$nextTick(() => {
        this.$refs['register-upgrade-dialog'].show()
      })
    },
    changeOption(id) {
      this.selectedPlanIdForTable = id
    },
    findGaId(plan_id, item) {
      const object = this.gaIdList.find(object => object.plan_id === plan_id)
      return item === 'table' ? object.button_in_table_id : object.button_in_list_id
    },
    selectPeriod(period) {
      this.period_option = period
    }
  },
  metaInfo() {
    return {
      title: 'Pricing',
      titleTemplate: '%s | OpenAPIHub',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/pricing' }
      ],
      meta: [
        { name: 'description', content: 'Explore details of different provider plans and start growing your business with OpenPIHub.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'Pricing | OpenAPIHub' },
        { property: 'og:description', content: 'Explore details of different provider plans and start growing your business with OpenPIHub.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/pricing' }
      ]
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 992px) {
  // mobile
  // .desktop-table {
  //   display: none;
  // }
}
@media (min-width: 992px) {
  // mobile
  .mobile-table {
    display: none;
  }
}
.scroll-link {
    font-weight: 600;
    color: #57ABF0;
    cursor: pointer;
}
.plan-table-title-container {
    text-align: center;
    .title {
        font-size: 20px;
        margin-bottom: 16px;
    }
    .description {
        margin-bottom: 32px;
    }
}
th {
    text-align: center;
}
td {
    font-size: 14px;
    line-height: 19px;
    padding-left: 16px !important;
    padding-right: 16px !important;
}
tr > td.best-value{
    border: 1px solid #1C344C;
}
tr > td.next-best-value{
    border-right: 1px solid #1C344C;
}
tr > th.best-value {
    background: #1C344C;
    color: #FFFFFF;
    // border-radius: 5px 5px 0 0;
    border: 1px solid #1C344C;
    padding: 6px;
}
tr > th:not(.best-value){
    border: none;
}
.table {
    table-layout: fixed;
    margin-bottom: 32px;
}
.table th, .table td {
  border: 1px solid #e7eaf3;
}
.table td {
  background-color: #FFFFFF;
}
.table > tbody > tr.first-row > td{
    padding-top: 24px;
    padding-bottom: 0px;
    border-bottom: none;
}
.table > tbody > tr.button > td, .table > tbody > tr.price-detail > td{
    padding: 0px;
}
.table > tbody > tr:not(.first-row, .last-row) > td{
    border-bottom: none;
    border-top: none;
}
.table > tbody > tr.last-row > td{
    border-top: none;
}
.best-value {
    font-size: 14px;
}
td > hr {
    margin-top: 0px;
    margin-bottom: 24px;
}
.plan-info-container {
    text-align: center;
    .plan-name {
        font-size: 20px;
        line-height: 1;
        color: #1C344C;
        margin-bottom: 16px;
    }
    .plan-description {
        margin-bottom: 16px;
    }
    .plan-price-container {
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .plan-currency {
            color: #57ABF0;
        }
        .plan-price {
            font-size: 30px;
            line-height: 1;
            color: #1C344C;
        }
    }
    .no-description {
      margin-bottom: 24px;
    }
    .price-description {
        margin-bottom: 24px;
        color: #999999;
    }
    .plan-button {
        button {
            width: 100%;
        }
    }
}
.detail-header {
    margin-bottom: 16px;
}
.detail-item {
    margin-bottom: 24px;
    .icon {
        color: #57ABF0;
        width: 12px;
        height: 9px;
    }
    .title {
        font-weight: 600;
        color: #1C344C;
        margin-bottom: 8px;
    }
    .point {
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
    }
}
.feature-list-container {
    hr {
        margin-top: 48px;
    }
    .align-items {
        padding-left: 18px
    }
}
.link-collapse {
    width: fit-content;
    margin: auto;
    color: #57ABF0;
}
.feature-table {
    width: 100%;
}
.feature-table th {
    text-align: left;
}
.feature-table .plan-name {
    text-align: center;
}
.faq-title {
    margin-bottom: 32px;
}
.card, .card-header, .card-btn {
  border-radius: 5px !important;
  box-shadow: none;
}
.card-header {
  background-color: transparent;
  border-bottom: none;
}
.card {
  border: 1px solid #DCDFE6;
}
.card-btn-toggle span {
  font-size: 21px;
  line-height: 1;
}
.collapse-show {
  border-top: 1px solid #E7EAF3;
}
.faq-list-item-heading {
  margin-top: 8px;
}
.custom-select {
  color: #505050;
}
.selectedCategory, .category-filter:hover {
  background: #1c344c !important;
  color: #fff !important;
}
#hubspot-form-dialog .modal-header {
  border-bottom: none;
}
#hubspot-form-dialog .modal-body {
  padding-top: 0px;
}
</style>
